<template>
  <div>
    <table aria-label="gross table" class="w-full">
      <thead>
        <tr>
          <th class=""></th>
          <th id="number" class="">#</th>
          <th
            class=" uppercase "
            id="header"
            v-for="(topHeader, index) in topHeaders"
            :key="index"
          >
            {{ topHeader.title }}
          </th>
        </tr>
      </thead>
      <tbody v-for="(data, i) in tableData" :key="i">
        <tr
          class="relative bg-ghostWhite text-sm font-semibold  transition-all duration-1000"
        >
          <td
            @click="tableData[i].show = !tableData[i].show"
            class="items-center"
          >
            <Icon
              :icon-name="data.show ? 'math_minus' : 'math_plus'"
              class="cursor-pointer text-flame"
            />
          </td>
          <td>
            <p>{{ i + 1 }}</p>
          </td>
          <td>
            <p>
              {{
                `${$getOrdinal(data.splitPosition)} Payment (${$getOrdinal(
                  data.payDay
                )})`
              }}
            </p>
          </td>
          <td class="" id="header"></td>
          <td class="" id="header"></td>
          <td class="" id="header">
            <p>{{ data.splitPercent + "%" }}</p>
          </td>
          <td class="" id="header">
            <p>{{ convertToCurrency(data.splitAmount) }}</p>
          </td>
        </tr>
        <tr v-if="data.show" style="background-color: white">
          <td id="number"></td>
          <td id="number"></td>
          <td
            class="text-flame text-xs font-bold uppercase"
            id="header"
            v-for="(header, index) in headers"
            :key="index"
          >
            <p>{{ header.title }}</p>
          </td>
        </tr>
        <tr
          v-show="data.show"
          v-for="(earning, index2) in data.payItems"
          :key="index2"
          class="text-sm "
          style="height: 48px; background-color: white"
        >
          <td></td>
          <td>
            {{ $getRomanFigure(index2 + 1) }}
          </td>
          <td>
            {{ earning.name }}
          </td>
          <td>
            {{ Capitalize(earning.type) }}
          </td>
          <td>
            {{ earning.rateType }}
          </td>
          <td>
            {{ earning.rate }}
          </td>
          <td>
            {{ earning.amount ? convertToCurrency(earning.amount) : "-" }}
          </td>
        </tr>
      </tbody>
      <tbody
        v-show="variableData.length > 0"
        v-for="(item, variableIndex) in variableData"
        :key="variableIndex"
      >
        <tr class="font-semibold">
          <td
            @click="
              variableData[variableIndex].show = !variableData[variableIndex]
                .show
            "
            class="items-center"
          >
            <Icon
              :icon-name="item.show ? 'math_minus' : 'math_plus'"
              class="cursor-pointer text-flame"
            />
          </td>
          <td></td>
          <td>
            <p>Variable Pay</p>
          </td>
          <td class="" id="header"></td>
          <td class="" id="header"></td>
          <td class="font-semibold" id="header">
            <p></p>
          </td>
          <td class="" id="header">
            <p></p>
          </td>
        </tr>
        <tr v-if="item.show" style="background-color: white">
          <td id="number"></td>
          <td id="number"></td>
          <td
            class="text-flame text-xs font-bold uppercase"
            id="header"
            v-for="(header, indexHeaders) in headers"
            :key="indexHeaders"
          >
            <p>{{ header.title }}</p>
          </td>
        </tr>
        <tr
          v-show="item.show"
          v-for="(earning, indexVariablePay) in item.payItems"
          :key="indexVariablePay"
          class="text-sm "
          style="height: 48px; background-color: white"
        >
          <td></td>
          <td>
            {{ $getRomanFigure(indexVariablePay + 1) }}
          </td>
          <td>
            {{ earning.name }}
          </td>
          <td>
            {{ Capitalize(earning.type) }}
          </td>
          <td>
            {{ earning.rateType }}
          </td>
          <td>
            {{ earning.rate }}
          </td>
          <td>
            {{ earning.amount ? convertToCurrency(earning.amount) : "-" }}
          </td>
        </tr>
      </tbody>
      <tbody
        style="height: 52px; border-spacing: 15px 0;"
        class="mt-4 w-full bg-ghostWhite totalTable"
        v-if="grossEarningsData.length"
      >
        <tr>
          <td class="" />
          <td class="" />
          <td class="font-bold text-lg  text-darkPurple ">
            Total Gross
          </td>
          <td class="" />
          <td class="" />
          <td class=" font-bold text-blueCrayola text-lg">
            {{ totalPercent + "%" }}
          </td>
          <td class="font-bold ">
            {{ convertToCurrency(totalAmount) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "AccordionPayInfoTable",
  props: {
    grossEarningsData: {
      type: Array,
      default() {
        return [];
      }
    },
    totalAmount: {
      type: Number,
      default: 0
    },
    totalPercent: {
      type: Number,
      default: 0
    },
    variableTable: {
      type: Object,
      default() {
        return {};
      }
    },
    showTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openedIndex: 0,
      headers: [
        { title: "pay item" },
        { title: "pay type" },
        { title: "rate type" },
        { title: "rate (%)" },
        { title: "amount(₦)" },
        { title: "" }
      ],
      topHeaders: [
        { title: "PayRun Type" },
        { title: "" },
        { title: "" },
        { title: "Pay Split" },
        { title: "Sub Total" }
      ],
      tableData: [],
      variableData: []
    };
  },
  watch: {
    grossEarningsData() {
      this.tableData = this.grossEarningsData.map(item => ({
        ...item,
        show: false
      }));
    },
    variableTable() {
      this.variableData = [this.variableTable].map(item => ({
        ...item,
        show: false
      }));
    }
  },
  methods: {}
};
</script>

<style scoped>
th {
  border: none;
  text-align: left;
  padding: 7px;
  min-height: 60px !important;
}
.totalTable::before {
  content: "";
  display: block;
  height: 24px;
  background-color: white;
  clear: both;
}
</style>
